




import { Component, Prop, Vue } from 'vue-property-decorator'
import WorkTimeIntro from '@/components/WorkTimeIntro.vue'

@Component({
  components: {
    WorkTimeIntro,
  },
})
export default class WorkTime extends Vue {}
