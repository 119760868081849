import Router from 'vue-router'
import Vue from 'vue'
import Home from './views/Home.vue'
import SharkEscape from './views/SharkEscape.vue'
import WorkTime from './views/WorkTime.vue'
import CoinShark from './views/CoinShark.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import TermsOfService from './views/TermsOfService.vue'
import routedata from '../routedata.json'
import VueAnalytics from 'vue-ua'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return new Promise((resolve) => {
      const timeout = 1000
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, timeout)
    })
  },
  routes: [
    {
      path: routedata.home.path,
      name: 'home',
      component: Home,
      meta: {
        title: routedata.home.title,
        metaTags: [
          {
            name: 'description',
            content: routedata.home.description,
          },
        ],
      },
    },
    {
      path: routedata.sharkEscape.path,
      name: 'shark-escape',
      component: SharkEscape,
      meta: {
        title: routedata.sharkEscape.title,
        metaTags: [
          {
            name: 'description',
            content: routedata.sharkEscape.description,
          },
        ],
      },
    },
    {
      path: routedata.worktime.path,
      name: 'worktime',
      component: WorkTime,
      meta: {
        title: routedata.worktime.title,
        metaTags: [
          {
            name: 'description',
            content: routedata.worktime.description,
          },
        ],
      },
    },
    {
      path: routedata.coinshark.path,
      name: 'coinshark',
      component: CoinShark,
      meta: {
        title: routedata.coinshark.title,
        metaTags: [
          {
            name: 'description',
            content: routedata.coinshark.description,
          },
        ],
      },
    },
    {
      path: routedata.privacyPolicy.path,
      name: 'privacy-policy',
      component: PrivacyPolicy,
      meta: {
        title: routedata.privacyPolicy.title,
        metaTags: [
          {
            name: 'description',
            content: routedata.privacyPolicy.description,
          },
        ],
      },
    },
    {
      path: routedata.termsOfService.path,
      name: 'terms-of-service',
      component: TermsOfService,
      meta: {
        title: routedata.termsOfService.title,
        metaTags: [
          {
            name: 'description',
            content: routedata.termsOfService.description,
          },
        ],
      },
    },
  ],
})

/*{
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" './components/About.vue'),
},*/

Vue.use(Router)
Vue.use(VueAnalytics, {
  // [Required] The name of your app as specified in Google Analytics.
  appName: 'Caro Apps',
  // [Required] The version of your app.
  appVersion: 'v1.0',
  // [Required] Your Google Analytics tracking ID.
  trackingId: 'UA-135652740-1',
  // If you're using vue-router, pass the router instance here.
  vueRouter: router,
  debug: false,
})

export default router
