import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import RootState from '@/types/RootState'
import home from '@/store/modules/home'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    version: '0.0.1',
  },
  modules: {
    home,
  },
}

export default new Vuex.Store<RootState>(store)
