












import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Footer extends Vue {
  private data(): any {
    return {
      privacyPolicyUrl: `${process.env.VUE_APP_BASE_URL}/privacy-policy`,
      tosUrl: `${process.env.VUE_APP_BASE_URL}/terms-of-service`,
    }
  }
}
