import { render, staticRenderFns } from "./CoinSharkIntro.vue?vue&type=template&id=71507b5d&scoped=true&lang=pug&"
import script from "./CoinSharkIntro.vue?vue&type=script&lang=ts&"
export * from "./CoinSharkIntro.vue?vue&type=script&lang=ts&"
import style0 from "./CoinSharkIntro.vue?vue&type=style&index=0&id=71507b5d&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71507b5d",
  null
  
)

component.options.__file = "CoinSharkIntro.vue"
export default component.exports