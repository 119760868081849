




import { Component, Prop, Vue } from 'vue-property-decorator'
import CoinSharkIntro from '@/components/CoinSharkIntro.vue'

@Component({
  components: {
    CoinSharkIntro,
  },
})
export default class CoinShark extends Vue {}
