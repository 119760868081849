







import { Component, Prop, Vue } from 'vue-property-decorator'
import SharkEscapeIntro from '@/components/SharkEscapeIntro.vue'

@Component({
  components: {
    SharkEscapeIntro,
  },
})
export default class SharkEscape extends Vue {}
