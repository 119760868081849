







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AppCard extends Vue {
  @Prop() private initialPath!: string
  @Prop() private appInfo!: string

  private pushToRoute(): void {
    this.$router.push(this.initialPath)
  }

  get imageSourcePath(): string {
    return require(`@/assets/${this.initialPath}-card.png`)
  }
}
