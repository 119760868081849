













import { Component, Vue } from 'vue-property-decorator'
import AppCard from '@/components/AppCard.vue'

@Component({
  components: {
    AppCard,
  },
})
export default class Landing extends Vue {}
