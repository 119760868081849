


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Carousel, Slide } from 'vue-carousel'

@Component({
  components: {
    Carousel,
    Slide,
  },
})
export default class CoinSharkIntro extends Vue {
  @Prop() private msg!: string
}
