
























import { Component, Prop, Vue } from 'vue-property-decorator'
import 'vue-hamburger/index.css'
import Hamburger from 'vue-hamburger'
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    'hamburger': Hamburger,
    'shark-footer': Footer,
  },
})
export default class App extends Vue {
  @Prop() private msg!: string
  @Prop() private initialTransitionName!: string
  @Prop() private initialMenuState!: boolean

  private data(): any {
    return {
      isMenuOpen: this.initialMenuState,
      transitionName: this.initialTransitionName,
    }
  }

  private setMenuState(menuState: boolean) {
    this.$data.isMenuOpen = menuState
  }

  private setTransitionName(transitionName: string) {
    this.$data.transitionName = transitionName
  }

  private created() {
    this.$router.beforeEach((to, from, next) => {
      this.setMenuState(false)
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.setTransitionName(toDepth < fromDepth ? 'slide-right' : 'slide-left')
      // This goes through the matched routes from last to first, finding the closest route with a title.
      // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
      const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title)

      // Find the nearest route element with meta tags.
      const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags)
      const previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags)

      // If a route with a title was found, set the document (page) title to that value.
      if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title
      }

      // Remove any stale meta tags from the document using the key attribute we set below.
      Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
        (el: any) => {
          if (el !== null) {
            el.parentNode.removeChild(el)
          }
        },
      )

      // Skip rendering meta tags if there are none.
      if (!nearestWithMeta) {
        return next()
      }

      // Turn the meta tag definitions into actual elements in the head.
      nearestWithMeta.meta.metaTags
        .map((tagDef: any) => {
          const tag = document.createElement('meta')

          Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key])
          })

          // We use this to track which meta tags we create, so we don't interfere with other ones.
          tag.setAttribute('data-vue-router-controlled', '')

          return tag
        })
        // Add the meta tags to the document head.
        .forEach((tag?: any) => {
          document!.head!.appendChild(tag)
        })

      next()
    })
  }
}
